import React from "react";
import { Container, Row, Col } from 'styled-bootstrap-grid'
import Img from "gatsby-image";
import { Link } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { parseOptions } from "../../utils/parseOptions";
import * as Global from "../../css/globalStyle";

import * as styled from "./styles";

export const NewSection = ({ title, linkSlug, linkText, content, image, imagePosition }) => {
  const checkIfInternalReg = new RegExp('^(http|https)://')
  const isInternalLink = !checkIfInternalReg.test(linkSlug)

  return (
    <Container
      fluid
      style={{
        margin: '25px 0 45px 0',
        width: '100%',
      }}>
      <styled.CustomRow
        imagePosition={imagePosition}
      >
        <styled.CustomCol
          md={6}
          style={{
            paddingLeft: imagePosition === 'right' ? '20%' : '40px',
            paddingRight: imagePosition === 'left' ? '20%' : '40px',
          }}
        >
          {title && (
            <h2>{title}</h2>
          )}
       {content && (
            <styled.SectionDescription>
              {documentToReactComponents(content.json, parseOptions)}
            </styled.SectionDescription>
          )}

          <styled.ButtonWrapper>
            {isInternalLink ? (
              <Link to={linkSlug}>
                <Global.button>{linkText}</Global.button>
              </Link> ) : (
              <a style={{ margin: '10px 0' }} target="_blank" href={linkSlug}>
                <Global.button>{linkText}</Global.button>
              </a>
            )}
           
          </styled.ButtonWrapper>
        </styled.CustomCol>
        <styled.CustomCol md={6}>
          <Img fluid={image.fluid} />
        </styled.CustomCol>
      </styled.CustomRow>
    </Container>
  );
};

export default NewSection;
